import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×6\\@80% 1RM`}</p>
    <p>{`Clean Grip RDL 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`5-Full Cleans (185/125)`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`4-Full Cleans`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`3-Full Cleans`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`2-Full Cleans`}</p>
    <p>{`20-Double Unders`}</p>
    <p>{`1-Full Clean`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is our Ville get together at 5:00pm at Foam Warriorz and then
dinner afterward.  There’s still time to RSVP if you can make it, but do
it soon!  Email or text Daniel if you can make it.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up January 12th.  We are sold
out but if you aren’t competing come out anyway and cheer on all our
teams!  We will be closing after the 4:30 class on Friday to set up.  If
you can help out please let us know, it will be greatly appreciated.`}</strong></p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s (11:15) and Sunday’s (1:45)
each weekend after our CrossFit classes.  Email Daniel for more
information.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      